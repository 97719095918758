<!-- 产品与服务3 智慧党建 -->
<template>
    <div class="product3">
        <banner-bar></banner-bar>

        <div class="boxes1" id="jumpPoint">
            <div class="pub_title_area">产品名称</div>
            <div class="name">智慧党建平台</div>
            <img src="./../../assets/Product3/img1.png" alt="">
        </div>

        <div class="boxes2">
            <div class="pub_title_area">产品详情</div>
            <div class="title">产品简介</div>
            <div class="content">{{content1}}</div>
            <img src="./../../assets/Product3/img2.png" alt="">
        </div>

        <div class="boxes3">
            <div class="title">功能总览</div>
            <div class="area1">
                <div class="left">
                    <div class="pub_small_title">双首页</div>
                    <div v-html="content2" class="pub_content" style="white-space:pre-wrap;">{{content2}}</div>
                </div>
                <img src="./../../assets/Product3/img3.png" alt="">
            </div>
            <div class="area2">
                <img src="./../../assets/Product3/img4.png" alt="">
                <div v-html="content3" class="pub_content" style="white-space:pre-wrap;">{{content3}}</div>
            </div>
        </div>

        <div class="boxes4">
            <div class="area">
                <div class="left">
                    <div class="pub_small_title">二维码</div>
                    <div v-html="content4" class="pub_content" style="white-space:pre-wrap;">{{content4}}</div>
                </div>
                <img class="img5" src="./../../assets/Product3/img5.png" alt="">
            </div>
            <div class="area">
                <img class="img6" src="./../../assets/Product3/img6.png" alt="">
                <div class="right">
                    <div class="pub_small_title">车间微党课</div>
                    <div v-html="content5" class="pub_content" style="white-space:pre-wrap;">{{content5}}</div>
                </div>
            </div>
            <div class="area">
                <div class="left">
                    <div class="pub_small_title">支部概况</div>
                    <div v-html="content6" class="pub_content" style="white-space:pre-wrap;">{{content6}}</div>
                </div>
                <img class="img7" src="./../../assets/Product3/img7.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import bannerBar from './../../components/bannerBar/bannerBar.vue'
export default{
    name:'Product3',
    components:{
        bannerBar
    },
    data(){
        return{
            content1:'为了深化党组织在制造业车间的引领作用,发挥党支部在生产中的助力效应，实现数字化赋能传统党建工作从实际意义上提升党员服务群众 的工作效率。我们结合合兴电子元件车间党支部线下特色业务闭环：红 色讲师团、红色调解员、创新党小组，党员联职工等模块进行数字化转型开发。在此基础上额外开发车间微党课、支部概况、党员亮相等模块，从实际应用角度出发提高原有的党建工作影响力。本项目紧密围绕合兴 电子元件车间党支部“支部建在车间、党员在您身边”的服务宗旨，通过我们的车间党支部智慧党建系统平台促进党组织与车间员工间紧密联系，方便员工操作，能够及时解决员工的困难，确保党建工作与车间生 产深度融合，深化党支部在企业生产经营中的实质性助力作用。',
            content2:'1.红色传帮带：\n一句话展示了红色师傅和帮带徒弟的数量。\n饼图的形式展现了各阶段帮带人数和新参加帮带人数。\n2.支部概况：\n视频区域放置了车间的宣传视频以双环饼图的形式分别展示了支部车间内党员（包含预备党员）的性别和\n学历比例文字形式展现支部概况。\n3.党员亮相：\n以轮播图形式展示党员的照片、姓名、职务、电话、 承诺。',
            content3:'4.党员联职工：\n两句话展示党员数、连职工数、今年解决难题数以饼图的形式展现了本月党员联系走访工作的完成度。\n5.创新党小组：\n以轮播图的形式展示了创新党小组的名称、项目进度、成立时期。\n6.车间微党课|红色讲师团：\n这两个模块共用一个视频播放区，点击任意模块的某个视频时，左上角的播放区会播放点击的党课或课件。',
            content4:'红色调解员：\n一句话展示了红色调解员和处理问题的数量\n柱状图的形式依次展现每个红色调解员当年已处理完问题的数量\n车间员工若遇到问题，可以用手机微信扫描二维码进行求助\n二维码下面的四个词展示了红色调解员可以处理事件的类别。',
            content5:'左侧播放区，右侧列表区。点击右侧，左侧播放区播放对应微党课，可全屏播放。',
            content6:'页面中包含四个模块：支部简介、活动风采、党史的今天、榜样的力量。\n党史的今天：会每天自动更新当天的内容，可以点击左右的按钮跳到上一天或下一天的党史。该模块内容滚动播放，鼠标移入该模块，停止滚动。鼠标移出，滚动开始。',
            infoes:[
                {
                    title:'产品背景',
                    content:'2021年5月11日上午10时，国家统计局局长宁吉喆在会上通报：我国60岁及以上人口的比重上升5.44个百分点。人口老龄化程度进一步加深，未来一段时期将持续面临人口长期均衡发展的压力。这一数据并且逐年增加。。如何让每个老人老有所依、老有所养，是国家政府同时也是每个家庭考虑的问题，现在空巢老人、留守老人居多，一些老人的健康问题、生活问题存在非常大的问题，老所依APP就是一款解决老人生活、健康问题的APP，用户可以根据老所依非常便捷预约生活、医疗、休闲服务，让每个老人、老有所依。',
                    imgUrl:require('./../../assets/Product3/img2.png')
                },
                {
                    title:'智慧养老平台',
                    content:'云谷智慧养老院服务管理平台是浙江云谷数据有限公司为养老机构、养老院、敬老院、养老福利院、老年公寓等养老服务机构量身定制开发的专业的养老APP软件产品；涵盖医疗服务、社区服务、养老机构、上门服务、助餐服务、老人活动参加、老人之间的社交老友圈等服务。 专门为此还开发出了智慧养老平台可视化大屏，方便观看老人的身体数据等待。通过明确部门职能，精简业务流程，优化服务项目，科学规范管理，提高养老机构业务处理效率和管理效率，减少资源浪费，提升核心竞争力。',
                    imgUrl:require('./../../assets/Product3/img3.png')
                },
            ],
            details:[
                {
                    title:'医疗服务',
                    branch:['在线医疗','专科问诊','医疗功能'],
                },
                {
                    title:'设备入网',
                    branch:['智能手环'],
                },
                {
                    title:'上门服务',
                    branch:['室内清洁','家具保养','家电维修'],                
                },
                {
                    title:'养老机构',
                    branch:['数 量','机构类别'],                
                },
            ],
        }
    },

    created(){
        this.$nextTick(()=>{
            this.tojumpPoint()
        })
    },

    methods:{
        tojumpPoint(){
            document.getElementById('jumpPoint').scrollIntoView({
                behavior:'smooth',
                block:'start'
            })
        }
    }
}
</script>
<style scoped lang="scss">
@import './Product3.scss'
</style>


